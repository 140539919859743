import React from "react";
import { BsCupHot  } from "react-icons/bs";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FaLinkedinIn  } from "react-icons/fa";
import Resume from '../images/work/wynonna_moo-resume.pdf';


const Footer = () => {


    return (

        <div className="footer" >

            <div className="leftcol copyright">
                <p>Made with lots of <BsCupHot strokeWidth={0.5} /> by Wy.</p>
                <p>&copy; 2019-2023</p>
            </div>
            <div className="rightcol links">
                <a href="https://www.linkedin.com/in/wynonna-moo" target="_blank">
                    <div className="link link1">
                        <FaLinkedinIn />
                        <p>linkedin</p>
                    </div>
                </a>
                <a href={Resume} target = "_blank">
                    <div className="link link2">
                        < InsertDriveFileOutlinedIcon />
                        <p>resumé</p>
                    </div>
                </a>
            </div>

        </div>

    )


}

export default Footer; 