import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import num01 from '../images/work/num01.svg';
import num02 from '../images/work/num02.svg';
import num03 from '../images/work/num03.svg';
import project01 from '../images/work/project01.png';
import project02 from '../images/work/project02.png';
import project03 from '../images/work/project03.png';
import ScrollToTop from './ScrollToTop';

const Work = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className='workWrapper'>
            <ScrollToTop />

            <div className='heroWrapper'>

                <div className='introText introTextSm'>
                    <div className='introTextDivider'></div>
                    <p className='introTextTitle'>Product designer based in Toronto, CA</p>
                </div>

                <div className='introText introTextName'>
                    <h1>wynonna</h1>
                    <h1 className='outline'>moo</h1>
                </div>

                <div className='introText introTextDesc'>
                    <p>Solving problems and crafting experiences</p>
                    <p>through the power of user-centric design</p>
                </div>

            </div>


            <div className='bannerWrapper'>
                <div className='bannerContent'>
                    <div className='bannerTextEng'>
                        <p className='bannerText'>Selected<span className='bannerTextBold'>Works</span></p>
                    </div>
                    <div className='bannerTextDivider'></div>
                    <div className='bannerTextCh'>
                        <p className='bannerText'>项<span className='bannerTextBold bannerTextBoldCh'>目</span></p>
                    </div>
                </div>
            </div>


            <div className='projectsWrapper'>

                <NavLink to="/te-advisor">
                    <div className='projectWrapper project01'>
                        <img src={num01} alt="Project 1: ThoughtExchange Advisor" />
                        <div className='projectContent'>
                            <h2>ThoughtExchange Advisor</h2>
                            <p>Designing an AI chat system to facilitate post-Engagement results analysis and insights sharing</p>
                            <img className='projectBannerPhoto' src={project01} alt="Banner photo for ThoughtExchange Advisor" />
                        </div>
                    </div>
                </NavLink>

                <NavLink to="/te-designsystem">
                    <div className='projectWrapper project02'>
                        <img src={num02} alt="Project 2: ThoughtExchange Design System" />
                        <div className='projectContent'>
                            <h2>ThoughtExchange Design System</h2>
                            <p>Refining the design system, one styling at a time</p>
                            <img className='projectBannerPhoto' src={project02} alt="Banner photo for ThoughtExchange Design System" />
                        </div>
                    </div>
                </NavLink>

                <NavLink to="/ics">
                    <div className='projectWrapper project03'>
                        <img src={num03} alt="Project 3: Indigenous Cultural Safety Dashboard" />
                        <div className='projectContent'>
                            <h2>Indigenous Cultural Safety Dashboard</h2>
                            <p>Raising awareness on bias against Indigenous peoples in healthcare</p>
                            <img className='projectBannerPhoto' src={project03} alt="Banner photo for Indigenous Cultural Safety Dashboard" />
                        </div>
                    </div>
                </NavLink>

            </div>
            
        
        
        </div>


    )


}

export default Work;