import React, { useEffect } from 'react';
import about01 from '../images/about/about01.png';
import about02 from '../images/about/about02.png';
import ScrollToTop from './ScrollToTop';


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (

        <div className='aboutWrapper'>
            <ScrollToTop />

            <div className='heroWrapper'>
                <div className='introText introTextSm'>
                    <div className='introTextDivider'></div>
                    <p className='introTextTitle'>Product designer based in Toronto, CA</p>
                </div>

                <div className='introText introTextName'>
                    <h1>wynonna</h1>
                    <h1 className='outline'>moo</h1>
                </div>

                <div className='introText introTextDesc'>
                    <p>Solving problems and crafting experiences</p>
                    <p>through the power of user-centric design</p>
                </div>
            </div>


            <div className='bannerWrapper'>
                <div className='bannerContent'>
                    <div className='bannerTextEng'>
                        <p className='bannerText'>About<span className='bannerTextBold'>Me</span></p>
                    </div>
                    <div className='bannerTextDivider'></div>
                    <div className='bannerTextCh'>
                        <p className='bannerText'><span className='bannerTextBold bannerTextBoldCh'>我</span></p>
                    </div>
                </div>
            </div>
            

            <div className='contentWrapper'>
                <div className='aboutContent aboutIntro'>
                    <h2>Hello, I’m Wynonna!</h2>
                    <p className='bodyLarge'>I’m a Product Designer with a diverse background and a deep passion for crafting exceptional digital experiences.</p>
                </div>

                <div className='aboutContent aboutImgs'>
                    <img src={about01} alt='Wynonna on Mount Whitney' />
                    <img src={about02} alt='Wynonna loves food' />
                </div>

                <div className='aboutContent aboutBody'>
                    <p className='bodyMed'>My journey has led me through various landscapes, from working in early-stage start-ups to the data department of my local health authority, and most recently, ThoughtExchange, where I contributed to pioneering an inclusive discussion platform that help organizations make data-driven decisions.</p>
                    <p className='bodySm'>Beyond the world of design, I'm an avid explorer of life's simple pleasures. Whether it's immersing myself in nature or discovering my next go-to coffee shop, I eagerly seek out new experiences that allow me to uncover life’s little details. This trait seamlessly translates to my work; I relish in making subtle changes that can lead to bigger impacts.</p>
                    <p className='bodySm'>Feel free to reach out if you'd like to learn more about my work or share your own adventure stories!</p>
                </div>

            </div>

        </div>

    )


}

export default About;