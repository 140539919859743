import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import posterImg from '../images/ics/bannerPhoto.png';
import persona from '../images/ics/persona.png';
import lowFi from '../images/ics/lowFiSketches.png';
import midFi from '../images/ics/midFiWireframes.png';
import highFi from '../images/ics/hiFi.png';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { LiaHospital, LiaBalanceScaleLeftSolid, LiaUnlinkSolid, LiaSyncSolid } from "react-icons/lia";

const ICS = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className='selectedWorkWrapper icsWrapper'>


            <div className='heroWrapper'>
                <div className='introText introTextSm'>
                    <div className='introTextDivider'></div>
                    <p className='introTextTitle'>Selected Work</p>
                </div>

                <div className='introText introTextName'>
                    <h1>Indigenous Cultural Safety dashboard</h1>
                    <p>Raising awareness on bias against Indigenous peoples in healthcare</p>
                </div>

                <div className='introText introTextTag'>
                    <p className='italicizedText'>user research, patient and healthcare data, dashboard design</p>
                </div>
                {/* <div className='heroBG'></div> */}
            </div>


            <div className='bannerWrapper'>
                <div className='bannerContent'>
                    <div className='bannerTextEng'>
                        <p className='bannerText'>SelectedWork<span className='bannerTextBold'>Three</span></p>
                    </div>
                    <div className='bannerTextDivider'></div>
                    <div className='bannerTextCh'>
                        <p className='bannerText'>项目<span className='bannerTextBold bannerTextBoldCh'>三</span></p>
                    </div>
                </div>
            </div>

            <div className='contentWrapper'>
                <div className='section01 section introduction'>
                    <img src={posterImg} alt='Banner photo for project' />
                    <p className='intro'>In 2020, <a href='https://engage.gov.bc.ca/app/uploads/sites/613/2020/11/In-Plain-Sight-Summary-Report.pdf'>In Plain Sight</a> reported how racism against Indigenous peoples was still evident in healthcare. Without evidence, the Indigenous Cultural Safety (ICS) team is unable make essential changes.</p>
                    <p>I was tasked with a first-of-its-kind project to help surface where disparities lie in the Indigenous patient journey. The goal was to raise awareness, create appropriate interventions, and ultimately generate systemic changes.</p>
                    <div className='stats'>
                        <p><span className='textDecor'>Duration: </span>6 months</p>
                        <p><span className='textDecor'>Year: </span>2022</p>
                        <p><span className='textDecor'>Role: </span>Lead UX designer</p>
                    </div>
                    <div className='disclaimer'>
                        <p>In compliance with the privacy policy of Fraser Health Authority, no sensitive data or real patient information was used in any of the wireframes and mockups.</p>
                    </div>
                </div>

                <div className='section02 section background'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>01</h3>
                        <h3 className='sectionNum'>Background</h3>
                    </div>
                    <div className='sectionContent'>
                        <p className='spacer'>There are many harmful myths about Indigenous people in healthcare that negatively affect the type of help they receive in hospitals. For example, when Indigenous people visit the emergency department (ED), they are more likely, than non-Indigenous people, to be seen as drug-seeking, causing them to not be triaged and taken care of properly.</p>
                        <p className='spacer'>In 2021, the ICS team wanted to raise awareness around these stigma and hold Fraser Health accountable. The team knew that Indigenous people were not treated equally as their non-Indigenous counterpart, but they lacked a meaningful visualization of the data to support their anecdotal hypothesis.</p>
                        <p className='spacer'>Since most complaints stem from the emergency department, we used that as the focus of this project. The project was divided into 2 phases to: the first phase focused on the Summary Page and the second phase included the 4 detailed pages (Arrival & Registration; Triage; Assessment & Treatment; and Discharge).</p>
                        <p className='spacer'>The high-level goals of this project was to: </p>
                        <ul>
                            <li><p>Shed light on the Indigenous patient journey through the ED</p></li>
                            <li><p>Bust harmful myths against Indigenous patient in healthcare</p></li>
                            <li><p>Leverage change and combat racism within the healthcare system</p></li>
                        </ul>
                    </div>
                </div> 

                <div className='section03 section myRole'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>02</h3>
                        <h3 className='sectionNum'>My role</h3>
                    </div>
                    <div className='sectionContent'>
                        <p className='spacer'>I lead the user research and design of the dashboard with one other designer. We interviewed the main users to discover the overarching goal of the product, current pain-points, and actionables they hope to acheive with the product.</p>
                        <p className='spacer'>After user interviews and analysis, we collaborated closely with the ICS team, data analysts, developers, and the managing consultant (MC) during the design phase to ensure data and technical feasiblity.</p>
                        <p className='spacer'>Phase one, the Summary Page, of the project launched and was in use January 2022.</p>
                    </div>
                </div> 

                <div className='section04 section defining'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>03</h3>
                        <h3 className='sectionNum'>Defining</h3>
                    </div>
                    <div className='sectionContent'>
                            <h4>Goals for this dashboard</h4>
                            <div className='subContentWrapper'>
                                <div className='subContent01 subContent'>
                                    <div className='goal01 goal'>
                                        <LiaHospital />
                                        <h5>Show quality of care</h5>
                                        <p>Prior to this project, there has been no previous efforts to bring to light the level of care received by Indigenous people</p>
                                    </div>
                                    <div className='goal02 goal'>
                                        <LiaBalanceScaleLeftSolid />
                                        <h5>Reveal disparities</h5>
                                        <p>Leverage data to show where the inequality lies between Indigenous and non-Indigenous patients in ED</p>
                                    </div>
                                </div>
                                <div className='subContent02 subContent'>
                                    <div className='goal03 goal'>
                                        <LiaUnlinkSolid />
                                        <h5>Bust myths</h5>
                                        <p>Disprove wrongful stereotypes that are feeding the racism and discrimination </p>
                                    </div>
                                    <div className='goal04 goal'>
                                        <LiaSyncSolid />
                                        <h5>Generate change</h5>
                                        <p>Hold Fraser Health accountable and create necessary changes (e.g. anti-racism education)</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div> 

                <div className='section05 section discovery'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>04</h3>
                        <h3 className='sectionNum'>Discovery</h3>
                    </div>
                    <div className='sectionContent'>
                            <h4>Understanding our users</h4>
                        <div className='subContent01 subContent'>
                            <h5>User demographics</h5>
                            <ul>
                                <li><p>Executive leadership of the Aboriginal Health program of FHA</p></li>
                                <li><p>Indigenous cultural safety team</p></li>
                            </ul>
                        </div>
                        <div className='subContent02 subContent'>
                            <h5>Persona</h5>
                            <p>Leadership, including managers and directors, from the ICS team manage topics and issues regarding Indigenous peoples, from Aboriginal staff employment to the Aboriginal Health Liaisons program offered in hospitals. Despite the importance of their jobs, the team lacked a proper method of visualizing the data.</p>
                            <figure>
                                <img src={persona} alt="Persona of a ICS manager" />
                                <figcaption>Persona of a ICS manager</figcaption>
                            </figure>
                        </div>
                        <div className='subContent02 subContent'>
                            <h5>Mapping out the patient journey</h5>
                            <p>The general patient journey can be summed up into 4 steps: Arrival & Registration, Triage, Assessment & Treatment, and Discharge.</p>
                            <figure>
                                <img src={persona} alt="Persona of a ICS manager" />
                                <figcaption>Persona of a ICS manager</figcaption>
                            </figure>
                        </div>
                        <div className='subContent03 subContent'>
                            <h5>Low-fidelity sketches</h5>
                            <figure>
                                <img src={lowFi} alt="InVision low-fidelity sketches" />
                                <figcaption>InVision low-fidelity sketches</figcaption>
                            </figure>
                        </div>
                        <div className='subContent04 subContent'>
                            <h5>Mid-fidelity mocks</h5>
                            <figure>
                                <img src={midFi} alt="Figma mid-fidelity wireframes for ideating variations" />
                                <figcaption>Figma mid-fidelity wireframes for ideating variations</figcaption>
                            </figure>
                        </div>
                        <div className='subContent05 subContent'>
                            <h5>High-fidelity prototype</h5>
                            <figure>
                                <img src={highFi} alt="High-fidelity prototype for testing with users" />
                                <figcaption>High-fidelity prototype for testing with users</figcaption>
                            </figure>
                        </div>
                    </div>
                </div> 

                <div className='section06 section reflections'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>05</h3>
                        <h3 className='sectionNum'>Reflections</h3>
                    </div>
                    <div className='sectionContent'>
                        <p className='spacer'>Prior to the start of this project, I was able to attend an Indigenous Cultural Safety 101 course taught by a fellow Métis staff member. I was shocked to learn about the true extent to which these stigma, discrimation, and racism stemmed from. The major distrust of Indigenous peoples with hospital comes from a history of being abused, tricked, and mistreated (e.g. unconsented sterilization, extreme use of restraints, using only local anesthesia for major surgeries).</p>
                        <p className='spacer'>I knew the work the ICS team does is important, but after the course and speaking with the ICS team, it really shifted my perspective on how urgent this work is.</p>
                        <p className='spacer'>As UX designers, we are trained to constantly question our own biases in any projects we handle. However, some are so ingrained into us that we may not even know it exists. I think it’s crucial for everyone, not just for work purposes, to educate and immerse themselves in other cultures. You never know what new biases you’ll discover that you carry, and chances are, you will.</p>
                    </div>
                </div> 

                <div className='projectNavWrapper'>
                    <div className='prevWrapper navDiv'>
                        <p className='bolded'>View previous project</p>
                        <NavLink to="/te-designsystem">
                            <div className='projectLink'>
                                <BsArrowLeft />
                                <p className='leftAlign'>ThoughtExchange Design System</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='nextWrapper navDiv'>
                        <p className='bolded'>View next project</p>
                        <NavLink to="/te-advisor">
                            <div className='projectLink'>
                                <p className='rightAlign'>ThoughtExchange Advisor</p>
                                <BsArrowRight />
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default ICS;