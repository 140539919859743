import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import posterImg from '../images/TEDS/project01.png';
import sectionFiveImg01 from '../images/TEDS/oldComp.png';
import sectionFiveImg02 from '../images/TEDS/inventory.png';
import sectionFiveImg03 from '../images/TEDS/newComp01.png';
import sectionFiveImg04 from '../images/TEDS/newComp02.png';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Document, Page } from 'react-pdf'
import coloursDoc from '../images/TEDS/colours.pdf';

const TEDS = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className='selectedWorkWrapper teDSWrapper'>


            <div className='heroWrapper'>
                <div className='introText introTextSm'>
                    <div className='introTextDivider'></div>
                    <p className='introTextTitle'>Selected Work</p>
                </div>

                <div className='introText introTextName'>
                    <h1>ThoughtExchange Design System</h1>
                    <p>Refining the design system, one styling at a time</p>
                </div>

                <div className='introText introTextTag'>
                    <p className='italicizedText'>design system creation, usage guidelines documentation, process improvements</p>
                </div>
            </div>


            <div className='bannerWrapper'>
                <div className='bannerContent'>
                    <div className='bannerTextEng'>
                        <p className='bannerText'>SelectedWork<span className='bannerTextBold'>Two</span></p>
                    </div>
                    <div className='bannerTextDivider'></div>
                    <div className='bannerTextCh'>
                        <p className='bannerText'>项目<span className='bannerTextBold bannerTextBoldCh'>二</span></p>
                    </div>
                </div>
            </div>

            <div className='contentWrapper'>
                <div className='section01 section introduction'>
                    <img src={posterImg} alt='Banner photo for project' />
                    <p className='intro'>Building and refining a design system (DS) is a complex undertaking that becomes even more challenging with no dedicated team in place. Recognizing the crucial role of a cohesive and organized system in supporting the company's growth, I lead the efforts in improving the design system.</p>
                    <p>The goal is to establish a unified source of truth for designers, developers and product managers alike.</p>
                    <div className='stats'>
                        <p><span className='textDecor'>Duration: </span>Nov 2022 - present</p>
                        <p><span className='textDecor'>Year: </span>2023</p>
                        <p><span className='textDecor'>Role: </span>Lead product designer</p>
                    </div>
                </div>

                <div className='section02 section context'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>01</h3>
                        <h3 className='sectionNum'>Context</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>One of the main challenges I faced was the absence of a pre-existing process for maintaining the design system. Since internal-facing projects do not show instantly recognizable results like new product features, it was difficult at first to convince dev leads to commit developers’ time for this initiative. </p>
                        <p>However, we highlighted the importance of this project by surfacing recurring issues. For example, similar components will have slight variations to them depending on which team was working on it. This caused a build up of design debt that needed to revisited.</p>
                        <p>By quantifying these issues, we received support and generated a collaborative approach to tackle the design system revamp alongside other projects.</p>
                    </div>
                </div> 

                <div className='section03 section goal'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>02</h3>
                        <h3 className='sectionNum'>Goals</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>Goals to accomplish by revamping the design system: </p>
                        <ol>
                            <li><p><span className='bolded'>Address brand inconsistencies:</span> Unify branding and feel for features throughout the product</p></li>
                            <li><p><span className='bolded'>Improve workflow efficiency:</span> Improve cross-team collaboration and working processes</p></li>
                            <li><p><span className='bolded'>Better scalability:</span> Set expectations for better maintenance and growth</p></li>
                        </ol>
                    </div>
                </div> 

                <div className='section04 section plan'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>03</h3>
                        <h3 className='sectionNum'>Plan of attack</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>After receiving approval from stakeholder, we established a roadmap for the revamp:</p>
                        <ol>
                            <li><p>Inventory & audit</p></li>
                            <li><p>Create the design system</p></li>
                            <li><p>Review & test</p></li>
                            <li><p>Publish & maintain</p></li>
                        </ol>
                    </div>
                </div> 

                <div className='section05 section inventory'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>04</h3>
                        <h3 className='sectionNum'>Plan in action</h3>
                    </div>
                    <div className='sectionContent'>
                        <h4>Inventory & audit: Colours</h4>
                        <figure>
                            <img src={sectionFiveImg01} alt="The last version of the components that are being revamped" />
                            <figcaption>The last version of the components that are being revamped</figcaption>
                        </figure>
                        <figure>
                            <img src={sectionFiveImg02} alt="Inventory of colours in the current design system, what was missing from auditing our product, and what else needs to be included in the new design system" />
                            <figcaption>Inventory of colours in the current design system, what was missing from auditing our product, and what else needs to be included in the new design systemd</figcaption>
                        </figure>
                        <p>In the old version, the Colours were being grouped by feature-specific colours. My developer and I noted that this was an issue, as the same colour (hex value) would be used elsewhere in the product, but it was being called as something else.</p>
                        <p>Thus, we decided on a new method of organization: defining a set of “base colours” and then applying these base colours to a feature. This solved the issue of having duplicate variables for the same colours.</p>
                        <figure>
                            <div className='images'>
                                <img src={sectionFiveImg03} alt="New design system" />
                                <img src={sectionFiveImg04} alt="New design system" />
                            </div>
                            <figcaption>Inventory of colours in the current design system, what was missing from auditing our product, and what else needs to be included in the new design system</figcaption>
                        </figure>
                
                    </div>
                </div> 

                <div className='section06 section reflections'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>05</h3>
                        <h3 className='sectionNum'>Reflections</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>Creating a design system is unlike creating a mockup or prototype; it takes another level of thoroughness and collaboration that is different from other client-facing projects. Every decision needs to be reviewed and walked through with several parties to ensure that it makes sense not just to me, but for anyone who will turn to this design system as the source of truth. Collaboration was not only helpful, but needed to help establish a process that will define the future of how the design system is handled.</p>
                        <p>I was immensely grateful for the opportunity to lead and contribute to a project that will have a lasting impact, both for internal teams as well as on future growth of the company. </p>
                    </div>
                </div> 

                <div className='section07 section nextSteps'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>06</h3>
                        <h3 className='sectionNum'>Next steps</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>We published and announced the new Colours format.  Now, we wait to see if there’s any feedback or issues when designers and developers begin using it in their workflow.</p>
                        <p>In the mean time, based off the priorities list in the roadmap, I will continue chipping away at this design system revamp.</p>
                    </div>
                </div> 

                <div className='projectNavWrapper'>
                    <div className='prevWrapper navDiv'>
                        <p className='bolded'>View previous project</p>
                        <NavLink to="/te-advisor">
                            <div className='projectLink'>
                                <BsArrowLeft />
                                <p className='leftAlign'>ThoughtExhange Advisor</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='nextWrapper navDiv'>
                        <p className='bolded'>View next project</p>
                        <NavLink to="/ics">
                            <div className='projectLink'>
                                <p className='rightAlign'>Indigenous Cultural Safety dashboard</p>
                                <BsArrowRight />
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default TEDS;