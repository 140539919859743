import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import posterImg from '../images/TEAdvisor/project01.png';
import sectionTwoImg01 from '../images/TEAdvisor/contextMock01.png';
import sectionTwoImg02 from '../images/TEAdvisor/contextMock02.png';
import sectionFourImg01 from '../images/TEAdvisor/quickMock01.png';
import sectionFourImg02 from '../images/TEAdvisor/quickMock02.png';
import sectionFourImg03 from '../images/TEAdvisor/demographics.png';
import sectionFiveImg01 from '../images/TEAdvisor/affinityMap.png';
import sectionFiveImg02 from '../images/TEAdvisor/persona01.png';
import sectionFiveImg03 from '../images/TEAdvisor/persona02.png';
import sectionFiveImg04 from '../images/TEAdvisor/opportunities01.png';
import sectionFiveImg05 from '../images/TEAdvisor/opportunities02.png';
import sectionFiveImg06 from '../images/TEAdvisor/opportunities03.png';
import sectionSixImg07 from '../images/TEAdvisor/solution01.png';
import sectionSixImg08 from '../images/TEAdvisor/solution02.png';
import sectionSixImg09 from '../images/TEAdvisor/solution03.png';
import sectionSevenImg10 from '../images/TEAdvisor/quotes01.png';
import sectionSevenImg11 from '../images/TEAdvisor/quotes02.png';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const TEAdvisor = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className='selectedWorkWrapper teAdvisorWrapper'>


            <div className='heroWrapper'>
                <div className='introText introTextSm'>
                    <div className='introTextDivider'></div>
                    <p className='introTextTitle'>Selected Work</p>
                </div>

                <div className='introText introTextName'>
                    <h1>ThoughtExchange Advisor</h1>
                    <p>Designing an AI chat system to facilitate post-Engagement results analysis and insights sharing</p>
                </div>

                <div className='introText introTextTag'>
                    <p className='italicizedText'>AI integration, new feature testing, user research, usability testing, data analysis, ideation, wireframing, UI design</p>
                </div>
            </div>


            <div className='bannerWrapper'>
                <div className='bannerContent'>
                    <div className='bannerTextEng'>
                        <p className='bannerText'>SelectedWork<span className='bannerTextBold'>One</span></p>
                    </div>
                    <div className='bannerTextDivider'></div>
                    <div className='bannerTextCh'>
                        <p className='bannerText'>项目<span className='bannerTextBold bannerTextBoldCh'>一</span></p>
                    </div>
                </div>
            </div>

            <div className='contentWrapper'>
                <div className='section01 section introduction'>
                    <img src={posterImg} alt='Banner photo for project' />
                    <p className='intro'>ThoughtExchange (TE) offers a platform for inclusive discussions and surveys that help their users make data-driven decisions. I helped design the integration of OpenAI into our Results dashboard to create a chat system that simplifies how our users analyze their Engagements.</p>
                    <div className='stats'>
                        <p><span className='textDecor'>Duration: </span>10 months</p>
                        <p><span className='textDecor'>Year: </span>2023</p>
                        <p><span className='textDecor'>Role: </span>Lead product designer</p>
                    </div>
                </div>

                <div className='section02 section context'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>01</h3>
                        <h3 className='sectionNum'>Context</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>The new OpenAI model offers strengths in both scaling with zero-shot and natural language processing, while also supporting one of ThoughtExchange's core values – reducing bias in analysis.</p>
                        <p>In the early stages of the project, I created an initial version of a basic chat interface feature (Advisor) to introduce the concept of a chat system within our product. The goals were to gauge user comfort with using an AI chat bot and evaluate how much impact it provided. This initial iteration had limited functionality, notably the lack of natural language processing capabilities and the ability to maintain context from previous messages.</p>
                        <figure>
                            <div className='images'>
                                <img src={sectionTwoImg01} alt='First version of Advisor, called Ask the Advisor' />
                                <img src={sectionTwoImg02} alt='First version of Advisor, called Ask the Advisor' />
                            </div>
                            <figcaption>First version of Advisor, called Ask the Advisor</figcaption>
                        </figure>
                    </div>
                </div> 

                <div className='section03 section goal'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>02</h3>
                        <h3 className='sectionNum'>Goal</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>The goal is to offer a natural, conversational interface for data analysis. Users can interact with this virtual advisor, asking questions just as they would with our consultant. By leveraging the familiarity of chat interfaces, we aim to make survey data analysis more accessible, user-friendly, and easily shareable.</p>
                    </div>
                </div> 

                <div className='section04 section research'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>03</h3>
                        <h3 className='sectionNum'>Research</h3>
                    </div>
                    <div className='sectionContent'>
                        <div className='subContent01 subContent'>
                            <h4>Quick Mocks</h4>
                            <p>Based on the feedback gathered from the first iteration of the Advisor, I revamped the second iteration to feature a more conversational interface. I then developed a prototype for the purpose of usability testing.</p>
                            <figure>
                                <div className='imagesMock mock01'>
                                    <img src={sectionFourImg01} alt='Low-fidelity mockups of Advisor version 2 for usability testing' />
                                    <img src={sectionFourImg02} alt='Low-fidelity mockups of Advisor version 2 for usability testing' />
                                </div>
                                <figcaption>Low-fidelity mockups of Advisor version 2 for usability testing</figcaption>
                            </figure>
                        </div>
                        <div className='subContent02 subContent'>
                            <h4>Demographics</h4>
                            <p>All the participants (8) we interviewed consented to being contacted via Gainsight survey responses.</p>
                            <figure>
                                <div className='imagesMock mock02'>
                                    <img src={sectionFourImg03} alt='Participant breakdown by vertical' />
                                </div>
                                <figcaption>Participant breakdown by vertical</figcaption>
                            </figure>
                        </div>
                        <div className='subContent03 subContent'>
                            <h4>Methodology</h4>
                            <p>Each testing session followed the same flow:</p>
                            <ol>
                                <li><p><span className='boldedText'>Introduction</span></p></li>
                                <li><p><span className='boldedText'>Pre-testing questions: </span>this allowed us to gain a deeper understanding of their existing workflow for post-Engagement result analysis, and provide insights on the first iteration of Advisor, including usage frequency and user pain-points</p></li>
                                <li><p><span className='boldedText'>Test: </span>participants were provided with the testing prototype and instructed to carry out a set of tasks (searching, saving, sharing) while thinking-aloud</p></li>
                                <li><p><span className='boldedText'>Post-testing questions: </span>this provided us with insights into their initial impressions, suggestions for improvements, and their vision for integrating the Advisor into their current workflow</p></li>
                                <li><p><span className='boldedText'>Debrief and follow-up</span></p></li>
                            </ol>
                        </div>
                    </div>
                </div> 

                <div className='section05 section results'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>05</h3>
                        <h3 className='sectionNum'>Results</h3>
                    </div>
                    <div className='sectionContent'>
                        <div className='subContent01 subContent'>
                            <h4>Post testing analysis</h4>
                            <p>We mapped and grouped insights to find common themes and overlaps.</p>
                            <figure>
                                <div className='imagesResults results01'>
                                    <img src={sectionFiveImg01} alt='Affinity mapping based off insights from usability testing to determine common groups' />
                                </div>
                                <figcaption>Affinity mapping based off insights from usability testing to determine common groups</figcaption>
                            </figure>
                        </div>
                        <div className='subContent02 subContent'>
                            <h4>User personas</h4>
                            <p>With speaking with our users, we had an idea of the two types of users: </p>
                            <figure>
                                <div className='imagesResults results02'>
                                    <img src={sectionFiveImg02} alt='Two user personas that portray the type of users of Advisors' />
                                    <img src={sectionFiveImg03} alt='Two user personas that portray the type of users of Advisors' />
                                </div>
                                <figcaption>Two user personas that portray the type of users of Advisors</figcaption>
                            </figure>
                        </div>
                        <div className='subContent03 subContent'>
                            <h4>Opportunities</h4>
                            <p>Regardless of persona, there were opportunities that would support pain-points experienced by both user types.</p>
                            <figure>
                                <div className='imagesResults results03'>
                                    <img src={sectionFiveImg04} alt='Opportunity 1' />
                                    <img src={sectionFiveImg05} alt='Opportunity 2' />
                                    <img src={sectionFiveImg06} alt='Opportunity 3' />
                                </div>
                            </figure>
                        </div>
                    </div>
                </div> 

                <div className='section06 section solution'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>06</h3>
                        <h3 className='sectionNum'>Solution</h3>
                    </div>
                    <div className='sectionContent'>
                            <h4 className='sectionTitle'>Applying findings to revamp of Advisor</h4>
                        <div className='subContent01 subContent'>

                            <div className='solution01 solution'>
                                <div className='opportunity'>
                                    <h5>Opportunity</h5>
                                    <p>Advisor provides insufficient context for the Engagement question being examined and it offers no guidance to users about their next steps</p>
                                </div>
                                <div className='feature'>
                                    <h5>Feature</h5>
                                    <p>Quick action chips as guides</p>
                                </div>
                                <figure>
                                    <div className='imagesSolutions solutions01'>
                                        <img src={sectionSixImg07} alt='Quick action chips as guides' />
                                    </div>
                                    <figcaption>Quick action chips as guides</figcaption>
                                </figure>
                            </div>

                            <div className='solution02 solution'>
                                <div className='opportunity'>
                                    <h5>Opportunity</h5>
                                    <p>Prompt generation is a new skill that most people do not have the time to learn, leading users to question the effectiveness of prompts they create themselves</p>
                                </div>
                                <div className='feature'>
                                    <h5>Feature</h5>
                                    <p>Prompt library with prompts sorted by common categories</p>
                                </div>
                                <figure>
                                    <div className='imagesSolutions solutions01'>
                                        <img src={sectionSixImg08} alt='Prompt library with prompts sorted by common categories' />
                                    </div>
                                    <figcaption>Prompt library with prompts sorted by common categories</figcaption>
                                </figure>
                            </div>

                            <div className='solution03 solution'>
                                <div className='opportunity'>
                                    <h5>Opportunity</h5>
                                    <p>Sharing insights is crucial for users, but the information is typically scattered across the product, lacking a convenient means of centralized collection and export</p>
                                </div>
                                <div className='feature'>
                                    <h5>Feature</h5>
                                    <p>Bookmarks panel that holds all saved insights and allows for editing, copy & paste, and exporting</p>
                                </div>
                                <figure>
                                    <div className='imagesSolutions solutions01'>
                                        <img src={sectionSixImg09} alt='Bookmarks panel that holds all saved insights and allows for editing, copy & paste, and exporting' />
                                    </div>
                                    <figcaption>Bookmarks panel that holds all saved insights and allows for editing, copy & paste, and exporting</figcaption>
                                </figure>
                            </div>

                        </div>

                    </div>
                </div> 

                <div className='section07 section impact'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>07</h3>
                        <h3 className='sectionNum'>Impact</h3>
                    </div>
                    <div className='sectionContent'>
                        <p>Implementation of this new iteration is scheduled to finish in October 2023.</p>
                        <p>Although further user testing and iterations are necessary to refine the Advisor, we have already received encouraging feedback from our users.</p>
                        <figure>
                            <div className='images'>
                                <img src={sectionSevenImg10} alt='It [Advisor] would not be part of the flow, it becomes the flow' />
                                <img src={sectionSevenImg11} alt='It [conversational layout] is useful for someone who does not know where to start' />
                            </div>
                        </figure>
                    </div>
                </div> 

                <div className='section08 section nextSteps'>
                    <div className='sectionNumWrapper'>
                        <h3 className='sectionNum'>07</h3>
                        <h3 className='sectionNum'>Takeaways & Next Steps</h3>
                    </div>
                    <div className='sectionContent'>
                        <div className='nextSteps01 nextStep'>
                            <h5>🫱🏽‍🫲🏻 Building trust</h5>
                            <p>Exploring additional and innovative strategies to foster trust between users and A, apart from mere usage, would be an intriguing opportunity for further investigation.</p>
                        </div>
                        <div className='nextSteps02 nextStep'>
                            <h5>🧑🏼‍💻 Catering to all</h5>
                            <p>Exploring additional and innovative strategies to foster trust between users and A, apart from mere usage, would be an intriguing opportunity for further investigation.</p>
                        </div>
                    </div>
                </div> 

                <div className='projectNavWrapper'>
                    <div className='prevWrapper navDiv'>
                        <p className='bolded'>View previous project</p>
                        <NavLink to="/ics">
                            <div className='projectLink'>
                                <BsArrowLeft />
                                <p className='leftAlign'>Indigenous Cultural Safety dashboard</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='nextWrapper navDiv'>
                        <p className='bolded'>View next project</p>
                        <NavLink to="/te-designsystem">
                            <div className='projectLink'>
                                <p className='rightAlign'>ThoughtExchange Design System</p>
                                <BsArrowRight />
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default TEAdvisor;