import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {

    return (
        <header className="header">
            <nav className="mainNav">

                <ul>
                    <li>
                        <NavLink to='/' exact>
                            <p>work</p>
                            <div className="navChWrapper">
                                <div className="lineNavWork lineNav"></div>
                                <div className="chNav"><p>项<span className="boldNav">目</span></p></div>
                            </div>

                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/about' exact>
                            <p>about</p>
                            <div className="navChWrapper">
                                <div className="lineNavAbout lineNav"></div>
                                <p><span className="boldNav">我</span></p>
                            </div>
                        </NavLink>
                    </li>
                </ul>

            </nav>
        </header>

    )

}

export default Header;