import '../styles/App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../comps/Header';
import Work from '../comps/Work';
import About from '../comps/About';
import TEDS from '../comps/TEDS';
import TEAdvisor from '../comps/TEAdvisor';
import ICS from '../comps/ICS';
import Footer from '../comps/Footer';

const App = () => {

  return (

    <div className='wrapper'>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Work/>} exact></Route>
          <Route path="/work" element={<Work/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/te-designsystem" element={<TEDS/>}></Route>
          <Route path="/te-advisor" element={<TEAdvisor/>}></Route>
          <Route path="/ics" element={<ICS/>}></Route>
        </Routes>
      </Router>
      <Footer />
    </div>

  )

}

export default App;
